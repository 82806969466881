<template>
  <a-form-model
    :class="$style.wrapper"
    :model="form"
    ref="form"
    hideRequiredMark
    validateOnRuleChange
    :rules="rules"
  >
    <p :class="$style.one">确定从云端彻底删除此文件？</p>
    <a-form-model-item prop="sync" label="">
      <a-radio-group v-model="form.sync">
        <a-radio :value="true">同步删除设备端的文件（如果存在）</a-radio>
      </a-radio-group>
    </a-form-model-item>
    <p :class="$style.bottom">{{ $t('hat.managementModal.file.delTip') }}</p>
  </a-form-model>
</template>
<script>
import { Component, Vue } from 'vue-property-decorator';
@Component()
export default class FileDelete extends Vue {
  form = {
    sync: false,
  };
  rules = {};
  getValue() {
    return this.form;
  }
}
</script>
<style lang="less" module>
.wrapper {
  text-align: center;
}
.one {
}
.bottom {
  color: var(--delete);
}
</style>
