<template>
  <div
    :class="$style.container"
    :style="
      isFull
        ? {
            height: '100vh',
            width: '100vw',
            position: 'fixed',
            top: 0,
            left: 0,
            zIndex: 101,
          }
        : ''
    "
  >
    <!-- <div :class="$style.mask" v-if="showMask"></div> -->
    <div :class="$style.map" id="map"></div>
    <a-icon
      :type="isFull ? 'shrink' : 'arrows-alt'"
      :class="$style.fullScreenIcon"
      @click="handleFullScreen"
    />
  </div>
</template>
<script>
import { Component, Vue, Prop } from 'vue-property-decorator';
import {
  funAMap,
  drawCharts,
  formatToApi,
  mouseEvent,
} from '@/views/hat/electron-fence/components/util';
import { FILE_SEARCH_EVENT_EMIT, TABS_KEYS } from '@/views/hat/file/index.vue';
import { fileMapList } from '@/services/smart-hat/file';
import { Debounce } from 'lodash-decorators';
import { createDrawer } from '@triascloud/x-components';
import FileDetail from './file-detail.vue';
import { getGeoFenceZoneList } from '@/services/smart-hat/screen';
import { UploadService } from '@triascloud/services';
import { Emitter } from '@/utils';

let map, AMap;
@Component()
export default class FileScreenMap extends Vue {
  @Prop({ type: Object, default: () => {} }) params;

  mounted() {
    this.initMap();
    Emitter.$on(FILE_SEARCH_EVENT_EMIT, this.handleSearch);
  }
  beforeDestroy() {
    Emitter.$off(FILE_SEARCH_EVENT_EMIT, this.handleSearch);
  }
  handleSearch(type, obj) {
    this.isLoad = false;
    this.fileMakers.length && this.clearFileMakers();
    this.chartList.length && map.remove(this.chartList);
    map.setZoom(4);
    map.setCenter([116.397428, 39.90923]);
    if (type === TABS_KEYS.FILE_MAP_MODE) {
      this.searchParams.content = obj.content;
      this.searchParams.groupId = obj.groupId;
      this.searchParams.projectId = obj.projectId;
      this.searchParams.type = obj.type;
      this.searchParams.startTime = obj.dateRange[0]
        ? this.$moment(obj.dateRange[0]).valueOf()
        : '';
      this.searchParams.endTime = obj.dateRange[1]
        ? this.$moment(obj.dateRange[1]).valueOf()
        : '';
      this.loadFilePositions();
    }
    this.isLoad = true;
  }
  showMask = false;
  async initMap() {
    this.showMask = true;
    AMap = await funAMap();
    const { mode } = this.$store.state.crossStorage.skin;
    map = new AMap.Map('map', {
      zoom: 4,
      center: [116.397428, 39.90923],
      mapStyle: mode === 'dark' ? 'amap://styles/dark' : '',
    });
    map.on('complete', () => {
      // 解决地图闪屏问题
      // setTimeout(() => {
      this.showMask = false;
      // }, 500);
    });
    this.zoomChange();
    this.loadFilePositions();
  }
  isLoad = true;
  zoomChange() {
    const change = async e => {
      let zoom = e.target.getZoom();
      if (zoom < 2.5) {
        // 世界
        this.searchParams.level = 'PROVINCE';
      }
      if (zoom >= 2.5 && zoom < 4.8) {
        // 全国
        this.searchParams.level = 'PROVINCE';
      }
      if (zoom >= 4.8 && zoom < 5.8) {
        // 省
        this.searchParams.level = 'PROVINCE';
      }
      if (zoom >= 5.8 && zoom < 8.8) {
        // 市
        this.searchParams.level = 'CITY';
      }
      if (zoom >= 8.8) {
        // 区
        this.searchParams.level = 'ADDRESS';
      }
      this.isLoad && this.loadFilePositions();
    };
    map.on('zoomchange', change);
  }
  searchParams = {
    content: '',
    groupId: '',
    projectId: '',
    latMax: '',
    latMin: '',
    lngMax: '',
    lngMin: '',
    level: 'PROVINCE',
    type: '',
    startTime: '',
    endTime: '',
  };
  fileMakers = [];
  @Debounce(800)
  async loadFilePositions() {
    this.fileMakers.length && this.clearFileMakers();
    this.chartList.length && map.remove(this.chartList);
    let northWest = map.getBounds().getNorthWest(); // 西北
    let southEast = map.getBounds().getSouthEast(); // 东南
    // 当纬度大于180时是否自动修正：传false自动修正，true为不修正
    const lnglatMax = new AMap.LngLat(southEast.lng, northWest.lat, true);
    const lnglatMin = new AMap.LngLat(northWest.lng, southEast.lat, true);
    this.searchParams.latMax = lnglatMax.lat;
    this.searchParams.latMin = lnglatMin.lat;
    this.searchParams.lngMax = lnglatMax.lng;
    this.searchParams.lngMin = lnglatMin.lng;
    this.searchParams.startTime = this.params.dateRange[0]
      ? this.$moment(this.params.dateRange[0]).valueOf()
      : '';
    this.searchParams.endTime = this.params.dateRange[1]
      ? this.$moment(this.params.dateRange[1]).valueOf()
      : '';
    const fileList = await fileMapList(this.searchParams);
    this.createPositionMakers(fileList);
  }
  createPositionMakers(fileList) {
    const fileSvg = `<svg
        t="1708909905919"
        class="icon"
        viewBox="0 0 1718 1024"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        p-id="16730"
        width="200"
        height="200"
      >
        <path
          d="M558.293857 890.401966h667.990172v44.658476h-667.990172v-44.658476z m0-111.205897h667.990172v44.658476h-667.990172v-44.658476z m0-111.583293h667.990172v44.658477h-667.990172v-44.658477z m0 0"
          fill="#9FA0A6"
          p-id="16731"
        ></path>
        <path
          d="M1137.218673 222.411794h178.004914v734.789189c0 37.991155-28.933661 66.799017-66.799017 66.799017H536.027518c-37.991155 0-66.799017-28.933661-66.799017-66.799017V66.799017C469.228501 28.933661 498.162162 0 536.027518 0h556.784276v178.004914h44.658476v44.40688h-0.251597z m-66.924816-22.140541V22.266339H536.027518c-24.656511 0-44.658477 20.001966-44.658476 44.658477V957.326781c0 24.656511 20.001966 44.658477 44.658476 44.658477h712.271253c24.656511 0 44.658477-20.001966 44.658477-44.658477V244.678133H1114.952334V200.019656h-44.658477v0.251597z m0 0"
          fill="#9FA0A6"
          p-id="16732"
        ></path>
        <path
          d="M803.223587 111.205897H447.087961c-24.656511 0-44.658477 20.127764-44.658477 44.406879V512c0 24.656511 20.001966 44.658477 44.658477 44.658477h356.135626c24.656511 0 44.658477-20.001966 44.658477-44.658477V155.864373c0-24.656511-20.001966-44.658477-44.658477-44.658476zM640.314496 434.885504c0 7.296314-3.019165 13.963636-8.554299 18.869779-5.786732 5.15774-13.083047 8.302703-20.882556 7.925307-7.547912 0.251597-15.221622-2.767568-20.882555-7.925307-5.409337-4.528747-8.5543-11.573464-8.5543-18.492384 0-7.296314 3.019165-14.341032 8.5543-18.869779 5.786732-5.786732 13.083047-8.805897 20.882555-8.554299 7.547912-0.251597 15.221622 2.767568 20.882556 7.925307 5.409337 4.780344 8.805897 11.825061 8.554299 19.121376z m54.596561-167.689435c0 11.825061-2.767568 23.398526-8.302703 33.965602-7.296314 12.454054-16.982801 23.398526-28.556266 31.952825-8.302703 6.038329-15.473219 13.334644-21.511547 21.511548-3.270762 5.786732-4.780344 12.202457-4.780344 18.492384v11.321867h-42.268305v-14.844226c-0.251597-10.315479 1.761179-20.630958 6.289926-29.814251 5.15774-9.434889 12.454054-17.611794 20.882555-24.404914 8.5543-6.289926 16.102211-14.341032 22.392138-22.769533 3.648157-5.786732 5.786732-12.202457 5.786732-19.121376 0.251597-7.296314-2.767568-14.341032-8.302702-18.869779-6.918919-5.15774-15.221622-7.547912-23.775922-6.918919-20.630958 0.251597-40.758722 8.302703-55.980344 22.392138v-49.564619c18.869779-10.063882 39.878133-15.221622 61.138084-14.844226 20.379361-1.257985 40.507125 4.780344 56.860933 16.731204 13.586241 11.07027 20.756757 27.424079 20.127765 44.784275z"
          fill="#515151"
          p-id="16733"
        ></path>
      </svg>`;
    const playSvg = `<svg
        t="1708911244150"
        class="icon"
        viewBox="0 0 1024 1024"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        p-id="17827"
        width="200"
        height="200"
      >
        <path
          d="M512 0C230.4 0 0 230.4 0 512s230.4 512 512 512 512-230.4 512-512S793.6 0 512 0z m0 981.333333C253.866667 981.333333 42.666667 770.133333 42.666667 512S253.866667 42.666667 512 42.666667s469.333333 211.2 469.333333 469.333333-211.2 469.333333-469.333333 469.333333z"
          fill="#ffffff"
          p-id="17828"
        ></path>
        <path
          d="M672 441.6l-170.666667-113.066667c-57.6-38.4-106.666667-12.8-106.666666 57.6v256c0 70.4 46.933333 96 106.666666 57.6l170.666667-113.066666c57.6-42.666667 57.6-106.666667 0-145.066667z"
          fill="#ffffff"
          p-id="17829"
        ></path>
      </svg>`;
    let positions = fileList.map(item => {
      let dom = '';
      item.total = item.total > 99 ? '99+' : item.total;
      const label = `<div class="label ${item.total ? '' : 'label__hidden'}">${
        item.total
      }</div>`;
      switch (item.type) {
        case 'PHOTO':
          dom = `<div class="custom-content-marker">
            <img src="${item.path}" />
            ${label}
          </div>`;
          break;
        case 'VIDEO':
          dom = `<div class="custom-content-marker">
            <div class="video-container">
              ${playSvg}
              <img src="${item.path}" />
            </div>
            ${label}
          </div>`;
          break;
        default:
          dom = `<div class="custom-content-marker">
            ${fileSvg}
            ${label}
          </div>`;
      }
      return {
        position: item.positionList[0].split(','),
        dom,
        country: item.country,
        province: item.province,
        city: item.city,
        district: item.district,
        township: item.township,
        address: item.address,
      };
    });
    positions.forEach((item, index) => {
      this.fileMakers[index] = new AMap.Marker({
        map: map,
        content: item.dom,
        position: [item.position[0], item.position[1]],
        offset: new AMap.Pixel(-13, -30),
      });
      this.fileMakers[index].on('click', () => {
        this.openFileDetail(positions[index]);
      });
    });
  }
  clearFileMakers() {
    this.fileMakers.forEach(item => {
      map.remove(item);
    });
  }
  openFileDetail(data) {
    const params = {
      country: data.country,
      province: data.province,
      city: data.city,
      district: data.district,
      township: data.township,
      address: data.address,
      startTime: this.searchParams.startTime,
      endTime: this.searchParams.endTime,
      content: this.searchParams.content,
      groupId: this.searchParams.groupId,
      projectId: this.searchParams.projectId,
      type: this.searchParams.type,
    };
    this.modalDrawer = createDrawer(
      () => (
        <FileDetail params={params} onToPosition={v => this.showFence(v)} />
      ),
      {
        title: '文件详情',
        width: 640,
        wrapClassName: 'fileDetail',
        onClose: () => {
          this.isLoad = true;
        },
      },
    );
  }
  zoneArray = [];
  chartList = [];
  async showFence(obj) {
    this.isLoad = false;
    this.chartList.length && map.remove(this.chartList);
    const param = {
      geofenceIds: obj.geofenceId,
    };
    const data = await getGeoFenceZoneList(param);
    this.zoneArray = formatToApi(data);
    this.chartList = drawCharts(this.zoneArray, AMap, (...args) =>
      mouseEvent.call(null, ...args, map),
    );
    map.add(this.chartList);
    map.setZoom(16);
    map.setCenter([obj.lng, obj.lat]);
    obj.path = await this.getFileUrl(obj);
    obj.positionList = [`${obj.lng},${obj.lat}`];
    await this.clearFileMakers();
    await this.createPositionMakers([obj]);
  }
  async getFileUrl(obj) {
    let path = obj.path;
    if (obj.type === 'VIDEO') {
      path =
        path + '?x-oss-process=video/snapshot,t_1000,f_jpg,w_50,h_50,m_fast';
    } else if (obj.type === 'PHOTO') {
      path = path + '?x-oss-process=image/resize,h_50,m_lfit';
    }
    const customService = new UploadService('/oss/iot/oss');
    const abPath = await customService.getAuth(path);
    return abPath;
  }
  isFull = false;
  handleFullScreen() {
    this.isFull = !this.isFull;
    this.$emit('handleFullScreen', this.isFull);
  }
}
</script>
<style lang="less" module>
.container {
  flex: 1;
  position: relative;
  .map {
    height: 100%;
    width: 100%;
  }
  .mask {
    position: absolute;
    top: 0;
    height: 100%;
    width: 100%;
    background: #000;
    z-index: 99;
  }
  .fullScreenIcon {
    position: absolute;
    top: 10px;
    right: 10px;
    font-size: 36px;
    color: var(--primary);
    cursor: pointer;
  }
}
:global {
  .fileDetail {
    .ant-drawer-body {
      padding: 0;
    }
  }
  .custom-content-marker {
    position: relative;
    width: 50px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #ffffff;
    box-shadow: 0px 0px 10px 0px #cdcdcd;
    border-radius: 6px;
    img {
      width: 88%;
      height: 88%;
      border-radius: 6px;
      object-fit: cover;
    }
    svg {
      width: 100%;
      height: 100%;
    }
    .video-container {
      width: 88%;
      height: 88%;
      position: relative;
      overflow: hidden;
      border-radius: 6px;
      & > img {
        height: 100%;
        width: 100%;
      }
      .icon {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        width: 60%;
        height: 60%;
        color: #fff;
      }
    }
    .label {
      position: absolute;
      top: -10px;
      right: -10px;
      height: 20px;
      min-width: 20px;
      padding: 0 2px;
      font-size: 12px;
      background: var(--primary);
      border-radius: 20px;
      color: #fff;
      text-align: center;
      line-height: 20px;
    }
    .label__hidden {
      display: none;
    }
  }
}
</style>
