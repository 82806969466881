<template>
  <div :class="$style.contentWrap">
    <a-table
      :class="$style.wrapper"
      :columns="columns"
      :data-source="tableData"
      :pagination="pagination"
      :scroll="{ x: 2000 }"
      :row-key="record => record.fileId"
      :loading="loading"
      @change="handlePagination"
    >
      <template slot="action" slot-scope="record">
        <div :class="$style.buttonGroups">
          <a :class="[$style.button]" @click.stop="handleDelete(record)"
            >删除</a
          >
          <a
            :class="[$style.button]"
            @click.stop="() => handleDownloadFile(record)"
            >下载</a
          >
          <a
            v-if="record.type === 'PHOTO'"
            :class="[$style.button]"
            @click.stop="handleMark(record)"
            >标签</a
          >
          <a :class="[$style.button]" @click.stop="handlePreview(record)"
            >预览</a
          >
        </div>
      </template>
    </a-table>
    <span v-if="!loading" :class="$style.totalCount">累计{{ size }}</span>
  </div>
</template>
<script>
import { Component, Vue, Prop } from 'vue-property-decorator';
import {
  fileTable,
  putRemark,
  deleteFile,
  fileTableDownload,
} from '@/services/smart-hat/file';
import {
  FileTypeList,
  TABS_KEYS,
  FILE_SEARCH_EVENT_EMIT,
  FILE_DOWNLOAD_EVENT_EMIT,
} from '@/views/hat/file/index.vue';
import { Emitter } from '@/utils';
import { clipboardWrite, download } from '@triascloud/utils';
import { hatColors } from '../device/index.vue';
import { createFormModal, createModal } from '@triascloud/x-components';
import MarkEdit from './mark-edit.vue';
import FileDelete from './file-delete.vue';
import ManageModel from '@/views/hat/device/manage-model/index.vue';
import FileMap from './file-map.vue';
import { Debounce } from 'lodash-decorators';
import VideoThumbnail from '@/views/hat/device/manage-model/file/video-thumbnail.vue';
import Preview from './preview/index.vue';

export const getExt = url => {
  const result = /\.([^.]+)$/.exec(url.split('?')[0]);
  return result ? result[1].toLowerCase() : null;
};

@Component()
export default class FileTable extends Vue {
  @Prop({ type: Object, default: () => {} }) params;

  searchParams = {};
  mounted() {
    this.searchParams.dateRange = this.params.dateRange;
    this.fetchTable();

    Emitter.$on(FILE_SEARCH_EVENT_EMIT, this.handleSearchCallback);
    Emitter.$on(FILE_DOWNLOAD_EVENT_EMIT, this.handleDownloadCallback);
  }
  beforeDestroy() {
    Emitter.$off(FILE_SEARCH_EVENT_EMIT, this.handleSearchCallback);
    Emitter.$off(FILE_DOWNLOAD_EVENT_EMIT, this.handleDownloadCallback);
  }

  handleSearchCallback(type, obj) {
    if (type === TABS_KEYS.ALL_FILE) {
      this.pagination.current = 1;
      this.searchParams = obj;
      this.searchParams.dateRange = obj.dateRange;
      this.fetchTable();
    }
  }
  handleDownloadCallback(obj) {
    this.pagination.current = 1;
    this.searchParams = obj;
    this.handleDownload();
  }

  async handleDelete(record) {
    try {
      const result = await createFormModal(() => <FileDelete />, {
        width: 400,
        title: '操作确认',
      });
      if (result) {
        await deleteFile({
          deleteFileType: 'CLOUD',
          syncDelete: result.sync,
          fileId: record.fileId,
        });

        this.pagination.current = 1;
        this.fetchTable();
      }
    } catch (error) {
      return false;
    }
  }
  @Debounce(200)
  async handleDownloadFile(record) {
    try {
      const name = record.fileId + '.' + getExt(record.path);
      await download(record.path, name);
      this.$message.success('下载成功！');
    } catch (error) {
      return false;
    }
  }
  handlePreview(record) {
    if (record.type === 'PHOTO') {
      Preview.createModal(record.path, [
        {
          id: record.fileId,
        },
      ]);
    } else if (record.type === 'VIDEO') {
      this.$preview(record.path);
    } else {
      this.$message.warning('该文件暂不支持预览');
    }
  }

  handleMark(record) {
    this.$router.push(`/hat/file/marking/${record.fileId}`);
  }

  async handleEditMark(record) {
    try {
      const result = await createFormModal(
        () => <MarkEdit mark={record.mark} />,
        {
          width: 400,
          title: '文件备注',
        },
      );
      if (result) {
        await putRemark({
          mark: result,
          fileId: record.fileId,
        });

        this.pagination.current = 1;
        this.fetchTable();
      }
    } catch (error) {
      return false;
    }
  }

  handleDetailItem(id) {
    if (this.$p.action('READ', '/hat/device')) {
      createModal(() => <ManageModel record={{ deviceId: id }} />, {
        width: 1280,
        title: this.$t('hat.deviceManagement.manage'),
        maskClosable: false,
        className: this.$style.ManageModel,
      });
    }
  }

  handleMap(record) {
    if (record.lng && record.lat) {
      const center = {
        lng: +record.lng,
        lat: +record.lat,
      };
      createModal(
        () => (
          <FileMap
            center={center}
            record={{
              deviceId: record.idxDeviceId,
            }}
          />
        ),
        {
          title: '地理位置',
          width: 700,
        },
      );
    }
  }

  async handleDownload() {
    try {
      const params = {
        size: this.pagination.pageSize,
        current: this.pagination.current,
      };
      if (this.searchParams.type) {
        params.type = this.searchParams.type;
      }
      if (this.searchParams.groupId) {
        params.groupId = this.searchParams.groupId;
      }
      if (this.searchParams.projectId) {
        params.projectId = this.searchParams.projectId;
      }
      if (this.searchParams.content) {
        params.content = this.searchParams.content;
      }
      if (
        this.searchParams.dateRange &&
        this.searchParams.dateRange.length > 0
      ) {
        params.startTime = this.$moment(
          this.searchParams.dateRange[0],
        ).valueOf();
        params.endTime = this.$moment(this.searchParams.dateRange[1]).valueOf();
      }
      await fileTableDownload(params);
      this.$message.success('导出成功，附件有效期限1小时，请及时查看/下载！');
    } catch (error) {
      return false;
    }
  }

  tableData = [];
  urlList = [];
  loading = false;
  size = '';
  async fetchTable() {
    try {
      this.loading = true;
      const params = {
        size: this.pagination.pageSize,
        current: this.pagination.current,
      };
      if (this.searchParams.type) {
        params.type = this.searchParams.type;
      }
      if (this.searchParams.groupId) {
        params.groupId = this.searchParams.groupId;
      }
      if (this.searchParams.projectId) {
        params.projectId = this.searchParams.projectId;
      }
      if (this.searchParams.content) {
        params.content = this.searchParams.content;
      }
      if (
        this.searchParams.dateRange &&
        this.searchParams.dateRange.length > 0
      ) {
        params.startTime = this.$moment(
          this.searchParams.dateRange[0],
        ).valueOf();
        params.endTime = this.$moment(this.searchParams.dateRange[1]).valueOf();
      }

      const { records, total, fileSizeTotal } = await fileTable(params);
      this.tableData = records;
      const urlList = [];
      records.forEach(v => {
        urlList.push(v.path);
      });
      this.urlList = urlList;
      this.pagination.total = total;
      this.loading = false;
      let size = fileSizeTotal;
      if (size / 1024 > 1) {
        this.size = (size / 1024).toFixed(2) + 'G';
      } else {
        this.size = size + 'M';
      }
    } catch (error) {
      this.loading = false;
    }
  }
  pagination = {
    current: 1,
    pageSize: 10,
    total: 0,
    showQuickJumper: true,
    showSizeChanger: true,
    pageSizeOptions: ['10', '20', '50', '100'],
    showTotal: total => this.$t('todo.flow.total', { total }),
  };
  handlePagination(data) {
    this.pagination = data;
    this.fetchTable();
  }
  get columns() {
    return [
      {
        dataIndex: 'index',
        customRender: (text, row, index) => <span>{index + 1}</span>,
        title: this.$t('hat.clockRecord.index'),
        align: 'center',
        width: 80,
      },
      {
        align: 'left',
        title: '文件',
        dataIndex: 'path',
        width: 120,
        ellipsis: true,
        customRender: (text, record) => (
          <span class={this.$style.fileWrap}>
            {record.type === 'PHOTO' ? (
              <x-oss-image
                class={this.$style.filePicture}
                basePath="/oss/iot/oss"
                ossPath={this.getRelativePath(record.path)}
                onClick={() =>
                  Preview.createModal(record.path, 'image', [
                    { id: record.fileId },
                  ])
                }
              />
            ) : null}
            {/* onClick={() => this.$preview(record.path, 'image')} */}
            {record.type === 'VIDEO' ? (
              <VideoThumbnail
                class={this.$style.fileVideo}
                ossPath={record.path}
              />
            ) : null}
            {record.type === 'AUDIO' ? (
              <span
                class={this.$style.fileVideo}
                onClick={() => this.$preview(record.path)}
              >
                <a-icon type="audio" class={this.$style.fileVideoIcon} />
              </span>
            ) : null}
            {record.type === 'OTHER' ? (
              <span
                class={this.$style.fileVideo}
                onClick={() => this.$preview(record.path)}
              >
                <a-icon
                  type="question-circle"
                  class={this.$style.fileVideoIcon}
                />
              </span>
            ) : null}
          </span>
        ),
      },
      {
        title: '创建用户',
        ellipsis: true,
        width: 180,
        customRender: (text, record) => (
          <div>
            <a-tooltip placement="top">
              <template slot="title">
                <div>
                  {record.roleCOList.length > 0 ? (
                    <div>
                      {this.$t('iotScreenManage.role')}：
                      {this.roleNames(record.roleCOList)}
                    </div>
                  ) : null}

                  <div
                    onClick={() => {
                      this.copyPhone(record.mobilePhone);
                    }}
                  >
                    {this.$t('enterpriseManagement.camera.tableColumn.phone')}：
                    {record.mobilePhone}
                  </div>
                </div>
              </template>
              {record.avatar ? (
                <x-oss-image
                  class={this.$style.avatar}
                  ossPath={record.avatar}
                />
              ) : null}
              <span class={this.$style.userName}>{record.userName}</span>
            </a-tooltip>
          </div>
        ),
      },
      {
        title: '来源设备',
        dataIndex: 'deviceName',
        ellipsis: true,
        scopedSlots: { customRender: 'project' },
        customRender: (text, record) => (
          <div
            class={[this.$style.cursor, this.$style.activeColor]}
            onClick={() => this.handleDetailItem(record.idxDeviceId)}
          >
            <span>{text}</span>
            <span
              style={{
                backgroundColor: hatColors[record.color],
              }}
              class={this.$style.hatSpan}
            >
              <x-icon class={this.$style.whiteColor} type="tc-icon-hat-smile" />
            </span>
          </div>
        ),
      },
      {
        title: '文件类型',
        dataIndex: 'type',
        width: 100,
        ellipsis: true,
        scopedSlots: { customRender: 'groupName' },
        customRender: text => {
          const result = FileTypeList.find(i => i.key === text);
          return result ? result.label : null;
        },
      },
      {
        title: '大小',
        dataIndex: 'size',
        width: 130,
        ellipsis: true,
        customRender: text => (text > 0 ? text + 'Mb' : 0),
      },
      {
        title: '位置',
        dataIndex: 'address',
        ellipsis: true,
        customRender: (text, record) => {
          let result;
          if (record.lng && record.lat) {
            result = (
              <span
                class={[this.$style.cursor, this.$style.activeColor]}
                onClick={() => this.handleMap(record)}
              >{`${record.lng}，${record.lat}`}</span>
            );
          }
          return result;
        },
      },
      {
        align: 'left',
        title: '创建时间',
        dataIndex: 'fileTime',
        ellipsis: true,
        customRender: text =>
          text ? this.$moment(text).format('YYYY-MM-DD HH:mm:ss') : '',
      },
      // {
      //   title: '标签',
      //   width: 160,
      //   dataIndex: 'mark',
      //   ellipsis: true,
      // },
      {
        title: '备注',
        dataIndex: 'mark',
        width: 280,
        ellipsis: true,
        customRender: (text, record) => (
          <span>
            {text}
            <span class={this.$style.ml10}>
              <a-icon type="edit" onClick={() => this.handleEditMark(record)} />
            </span>
          </span>
        ),
      },
      {
        title: this.$t('enterpriseManagement.camera.tableColumn.operation'),
        fixed: 'right',
        key: 'operation',
        ellipsis: true,
        scopedSlots: { customRender: 'action' },
      },
    ];
  }
  copyPhone(val) {
    clipboardWrite(val).then(() => {
      this.$message.success('复制成功');
    });
  }
  roleNames(list) {
    let str = list.reduce((v, a) => v + a.roleName + '、', '');
    if (str.slice(-1) === '、') {
      str = str.slice(0, -1);
    }
    return str;
  }
  getRelativePath(abPath) {
    const result = abPath.match(/com\/(\S*)\?/)[1];
    return result + '?x-oss-process=image/resize,h_50,m_lfit';
  }
}
</script>
<style lang="less" module>
.wrapper {
  position: relative;
  :global {
    .ant-table-tbody > tr > td {
      vertical-align: middle;
    }
  }
}
.fileWrap {
  height: 61px;
  line-height: 61px;
  display: inline-block;
}
.filePicture {
  width: 44px;
  height: 44px;
  border-radius: 8px;
  object-fit: cover;
  cursor: pointer;
  transform: translateY(20%);
}
.fileVideo {
  width: 44px;
  height: 44px;
  transform: translateY(20%);
  cursor: pointer;
  overflow: hidden;
  border-radius: 8px;
}
.avatar {
  height: 24px;
  width: 24px;
  border-radius: 50%;
  object-fit: cover;
  transform: translateY(28%);
}
.noAvatar {
  display: inline-block;
  color: #fff;
  background-color: var(--x-modal-select-avatar);
  text-align: center;
  line-height: 24px;
  font-size: 12px;
  transform: translateY(0);
}
.userName {
  margin: 0 5px;
}
.buttonGroups {
  display: flex;
  gap: 20px;
}
.whiteColor {
  color: #fff;
}
.hatSpan {
  width: 32px;
  height: 16px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  margin-left: 6px;
}
.thumbnail {
  height: 24px;
  width: 24px;
  cursor: pointer;
  overflow: hidden;
  border-radius: 24px;
  display: inline-flex;
}
.ml10 {
  margin-left: 10px;
}
.cursor {
  cursor: pointer;
}
.unknown {
  font-size: 24px;
}
.activeColor {
  color: var(--font-active);
}
.contentWrap {
  position: relative;
}
.totalCount {
  font-size: 12px;
  color: var(--font-sub);
  display: inline-block;
  position: relative;
  left: 0;
  top: -32px;
}
</style>
