var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:_vm.$style.container,style:(_vm.isFull
      ? {
          height: '100vh',
          width: '100vw',
          position: 'fixed',
          top: 0,
          left: 0,
          zIndex: 101,
        }
      : '')},[_c('div',{class:_vm.$style.map,attrs:{"id":"map"}}),_c('a-icon',{class:_vm.$style.fullScreenIcon,attrs:{"type":_vm.isFull ? 'shrink' : 'arrows-alt'},on:{"click":_vm.handleFullScreen}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }